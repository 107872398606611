// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ジャンルタイトルと Read more を縦並びに */
.genre-article-slider-header {
    display: flex;
    flex-direction: column; /* 縦方向に並べる */
    align-items: flex-start;
    padding: 10px 15px;
    border-bottom: 2px solid #ccc;
}

.genre-articleslider-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 5px; /* Read more との間隔を調整 */
}

.genre-article-slider-read-more-link {
    font-size: 1rem;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
}

.genre-article-slider-read-more-link:hover {
    color: #0056b3;
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/Component/GenreArticleSliderComponent/GenreArticleSliderComponent.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,aAAa;IACb,sBAAsB,EAAE,YAAY;IACpC,uBAAuB;IACvB,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,wBAAwB;IACxB,kBAAkB,EAAE,sBAAsB;AAC9C;;AAEA;IACI,eAAe;IACf,cAAc;IACd,qBAAqB;IACrB,iBAAiB;IACjB,kCAAkC;AACtC;;AAEA;IACI,cAAc;IACd,0BAA0B;AAC9B","sourcesContent":["/* ジャンルタイトルと Read more を縦並びに */\n.genre-article-slider-header {\n    display: flex;\n    flex-direction: column; /* 縦方向に並べる */\n    align-items: flex-start;\n    padding: 10px 15px;\n    border-bottom: 2px solid #ccc;\n}\n\n.genre-articleslider-title {\n    font-size: 1.5rem;\n    font-weight: bold;\n    color: var(--text-color);\n    margin-bottom: 5px; /* Read more との間隔を調整 */\n}\n\n.genre-article-slider-read-more-link {\n    font-size: 1rem;\n    color: #007bff;\n    text-decoration: none;\n    font-weight: bold;\n    transition: color 0.3s ease-in-out;\n}\n\n.genre-article-slider-read-more-link:hover {\n    color: #0056b3;\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
