import React, { useEffect, useState, useMemo,useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./UserHistoryArticleSlider.css";
import ArticleSliderComponent from '../ArticleSliderComponent/ArticleSliderComponent';
const UserHistoryArticleSlider = () => {
  const [genres, setGenres] = useState([]);
  const [articlesByGenre, setArticlesByGenre] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  const apiUrl = useMemo(() => {
    if (process.env.REACT_APP_ENV === "Development") {
      return `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_API_ARTICLE_USERHISTORY}`;
    } else {
      return `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_API_ARTICLE_USERHISTORY}`;
    }
  }, []);
  useEffect(() => {
    const savedGenres = JSON.parse(localStorage.getItem("selectedGenres")) || [];
    const latestGenres = savedGenres.slice(0, 3); // 最新3件
    setGenres(latestGenres);
    //console.log("Loaded genres from localStorage:", latestGenres);
  }, []);

  useEffect(() => {
    const fetchArticles = async () => {
      if (genres.length === 0) return;

      setLoading(true);
      setError(null);
      
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ genres }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setArticlesByGenre(data.articlesByGenre || {});
      } catch (err) {
        setError("記事の取得中にエラーが発生しました");
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [genres, apiUrl]);

  if (loading) return <p>Loading articles...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="user-history-article-slider">
      {Object.entries(articlesByGenre).length > 0 ? (
        Object.entries(articlesByGenre).slice(0, 3).map(([genre, articles]) => (
          <div key={genre} className="user-history-article-section">
           <div className="user-history-article-title">{genre}</div>
            {articles.length > 0 ? (
              <UserHistoryArticleList 
              articles={articles} 
              sliderRef={sliderRef}
              navigate={navigate}
              />
            ) : (
              <p className="user-history-no-articles">記事がまだありません</p>
            )}
          </div>
        ))
      ) : (
        <p className="user-history-no-articles">記事がまだありません</p>
      )}
    </div>
  );
  
};
const UserHistoryArticleList = ({ articles ,sliderRef,navigate  }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 5;

  const nextArticle = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + itemsPerPage < articles.length ? prevIndex + 1 : 0
    );
  };

  const prevArticle = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 >= 0 ? prevIndex - 1 : articles.length - 1
    );
  };

  const handleArticleClick = (articleUID) => {
    navigate(`/article/${articleUID}`);
  };
  const getVisibleArticles = () => {
    return articles.slice(currentIndex, currentIndex + itemsPerPage);
  };

  return <ArticleSliderComponent articles={articles} />;
};
export default UserHistoryArticleSlider;
