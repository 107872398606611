import React, { useState, useEffect } from 'react';
import './ProviderArticleSlider.css';
import arrowBack from '../../Assets/svg/arrow_back.svg';
import arrowForward from '../../Assets/svg/arrow_forward.svg';
import FavoriteButton from "../Items/FavoriteMarker/FavoriteButton";
import { useNavigate } from "react-router-dom";

const ProviderArticleSlider = ({ provider }) => {
    const [articlesByProvider, setArticlesByProvider] = useState({});
    const [currentSlide, setCurrentSlide] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const navigate = useNavigate();

    // "NEW" 判定ロジック
    const isNew = (updatedDate) => {
        const now = new Date();
        const updated = new Date(updatedDate);
        const differenceInHours = (now - updated) / (1000 * 60 * 60);
        return differenceInHours <= 24; 
    };

   
    useEffect(() => {
        if (!provider) return;

        const initialSlideState = { [provider]: 0 };
        setCurrentSlide(initialSlideState);

        const providerArticlesUrl =
            process.env.REACT_APP_ENV === 'Development'
                ? `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_BACK_API_PROVIDER}${process.env.REACT_APP_BACK_API_SIDE_DISH_PROVIDER}/${encodeURIComponent(provider)}`
                : `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_BACK_API_PROVIDER}${process.env.REACT_APP_BACK_API_SIDE_DISH_PROVIDER}/${encodeURIComponent(provider)}`;

        setLoading(true);

        fetch(providerArticlesUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch articles from the same provider');
                }
                return response.json();
            })
            .then((data) => {
                setArticlesByProvider((prev) => ({
                    ...prev,
                    [provider]: data,
                }));
                setLoading(false);
            })
            .catch((err) => {
                setErrors(err.message);
                setLoading(false);
            });
    }, [provider]);

    const getVisibleArticles = (articles, startIndex) => {
        if (!Array.isArray(articles) || articles.length === 0) {
            console.warn("Invalid articles array:", articles);
            return [];
        }
        const total = articles.length;
        return [
            articles[(startIndex + total - 1) % total],
            articles[startIndex % total],
            articles[(startIndex + 1) % total],
        ];
    };

    const handleNext = () => {
        setCurrentSlide((prev) => ({
            ...prev,
            [provider]: (prev[provider] + 1) % (articlesByProvider[provider]?.length || 1),
        }));
    };

    const handlePrev = () => {
        setCurrentSlide((prev) => ({
            ...prev,
            [provider]:
                (prev[provider] - 1 + (articlesByProvider[provider]?.length || 1)) %
                (articlesByProvider[provider]?.length || 1),
        }));
    };

    const handleArticleClick = (articleUID) => {
        if (!articleUID) {
            console.error("Invalid article UID:", articleUID);
            return;
        }
        navigate(`/article/${articleUID}`);
        window.scrollTo(0, 0);
    };

    if (loading) return <p>Loading...</p>;
    if (errors) return <p>Error: {errors}</p>;
    if (!articlesByProvider[provider] || articlesByProvider[provider].length === 0) {
        return <p>No articles found for this provider.</p>;
    }

    const visibleArticles = getVisibleArticles(articlesByProvider[provider], currentSlide[provider]);
    const totalSlides = articlesByProvider[provider]?.length || 0;
    return (
        <div className="provider-slider-container">
            <div className="provider-slider-header">
                <h2 className="provider-slider-title">Articles by {provider}</h2>
                <div className="provider-slider-read-more">
                    <a
                        href={`/provider/${encodeURIComponent(provider)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Read more about {provider}
                    </a>
                </div>
            </div>
            <div className="provider-slider-content-wrapper">
                <button
                    className="provider-slider-control provider-slider-prev"
                    onClick={handlePrev}
                >
                    <img src={arrowBack} alt="Previous" />
                </button>
                <div className="provider-slider-content">
                    {visibleArticles.map((article, index) => {
                        if (!article || typeof article !== "object") {
                            console.error("Invalid article object:", article);
                            return null;
                        }
                        return (
                            <div
                                key={article.UID || index}
                                className="provider-slider-item"
                                onClick={() => handleArticleClick(article.UID)}
                            >
                                <div className="provider-slider-item-header">
                                    <h3>{article.ReportName || "Untitled Article"}</h3>
                                </div>
                                <div className="provider-slider-item-body">
                                    <p>提供者: {article.ProviderName || "Unknown"}</p>
                                    <p>提供日: {article.CreatedDate ? new Date(article.CreatedDate).toLocaleDateString() : "No date"}</p>
                                    <p>ジャンル: {article.Genres || "No genres"}</p>
                                </div>
                                <div className="provider-slider-item-footer">
                                    <FavoriteButton article={article} />
                                    {isNew(article.UpdatedDate) && (
                                        <div className="provider-slider-new-label">NEW</div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <button
                    className="provider-slider-control provider-slider-next"
                    onClick={handleNext}
                >
                    <img src={arrowForward} alt="Next" />
                </button>
            </div>
            {/* ページインジケーター */}
            <div className="provider-slider-indicator">
                {Array.from({ length: totalSlides }).map((_, index) => (
                    <span
                        key={index}
                        className={`provider-slider-indicator-dot ${
                            currentSlide[provider] === index ? "active" : ""
                        }`}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default ProviderArticleSlider;