import React, { useState, useCallback, memo } from 'react';
import GenreSelector  from '../GenreSelector/genreSelector';
import LatestArticlesComponent from '../LatestArticleComponent/LatestArticleComponent';
import GenreArticlesComponent from '../GenreArticleComponent/GenreArticleComponent'
import genresData from '../GenreSelector/genreAssets';
import GenreArticleSliderComponent from '../GenreArticleSliderComponent/GenreArticleSliderComponent';
import './MainPage.css'; 
import UserHistoryArticleSlider from '../UserHistoryArticleSlider/UserHistoryArticleSlider';
import EditorsPickComponent from '../EditorsPickComponent/EditorsPickComponent';

const MainPage = memo(() => {
 const [selectedSubGenres, setSelectedSubGenres] = useState([]);
 const [isGenreSelected, setIsGenreSelected] = useState(false);
  const getParentGenres = useCallback((subGenres) => {
    const parentGenres = {};
    subGenres.forEach(sub => {
      Object.keys(genresData).forEach(parent => {
        if (genresData[parent].includes(sub)) {
          if (!parentGenres[parent]) {
            parentGenres[parent] = [];
          }
          parentGenres[parent].push(sub);
        }
      });
    });
    return parentGenres;
  }, []);  

  const parentGenres = getParentGenres(selectedSubGenres);  

  const handleGenreSelect = (genres) => {
    setSelectedSubGenres(genres);
    setIsGenreSelected(genres.length > 0); 
    
  };
  return (
    <div className="main-page">
      <div className="mainpage-genre-selector">
        <GenreSelector onGenreSelect={handleGenreSelect} />
      </div>
      <div className="mainpage-article-area">
      {!isGenreSelected && (
          <>
            {/* 最新の記事セクション */}
            <div className="mainpage-latest-updates-section">
              <h2>最近の更新</h2>
              <LatestArticlesComponent />
            </div>
            <h2>あなたが気になりそうな記事</h2>
            <UserHistoryArticleSlider />
            <h2>編集者のおすすめ</h2>
            <EditorsPickComponent />
          </>
        )}

        {isGenreSelected && (
          <div className="mainpage-genre-article-section">
            {Object.entries(parentGenres).map(([parentGenre, subGenres]) => (
              <div key={parentGenre} className="mainpage-genre-articles">
                <h2>{parentGenre}</h2>
                <GenreArticleSliderComponent genres={subGenres} />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="mainpage-right-side">
       
      
      </div>
    </div>
  );
  
});

export default MainPage;
