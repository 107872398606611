// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ルート要素の横スクロールを防ぐ */
html, body {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }
  
  /* #rootの横幅を画面に固定する */
  #root {
    width: 100%;
    max-width: 100vw; /* 画面の最大幅に制限 */
    overflow-x: hidden;
    margin: 0 auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/global.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,UAAU;EACZ;;EAEA,qBAAqB;EACrB;IACE,WAAW;IACX,gBAAgB,EAAE,cAAc;IAChC,kBAAkB;IAClB,cAAc;EAChB","sourcesContent":["/* ルート要素の横スクロールを防ぐ */\nhtml, body {\n    width: 100%;\n    max-width: 100%;\n    overflow-x: hidden;\n    margin: 0;\n    padding: 0;\n  }\n  \n  /* #rootの横幅を画面に固定する */\n  #root {\n    width: 100%;\n    max-width: 100vw; /* 画面の最大幅に制限 */\n    overflow-x: hidden;\n    margin: 0 auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
