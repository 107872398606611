// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-page {
  display: flex;
  height: 100vh;
  width: calc(100vw - 0px); /* スクロールバー幅を考慮 */
  overflow: hidden;
}
.mainpage-article-area h2 {
  font-size: 1.8rem; /* サイズを少し大きく */
  font-weight: bold;
  margin: 20px 0; /* 上下の余白 */
  padding: 20px 0 10px 15px; /* 左側に 15px のパディングを追加 */
  border-bottom: 2px solid #ccc; /* 見やすい下線 */
  text-align: left; /* 左寄せ */
  color: var(--text-color, #333);
}
.mainpage-genre-selector {
  flex: 0 0 20%;
  border-right: 1px solid #ccc;
  height: 100%;
  overflow-y: auto;
}

.mainpage-article-area {
  flex: 0 0 80%;
  overflow-y: auto;
}

.mainpage-right-side {
  flex: 0 0;
  height: 100%;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/Component/MainPage/MainPage.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,wBAAwB,EAAE,gBAAgB;EAC1C,gBAAgB;AAClB;AACA;EACE,iBAAiB,EAAE,cAAc;EACjC,iBAAiB;EACjB,cAAc,EAAE,UAAU;EAC1B,yBAAyB,EAAE,uBAAuB;EAClD,6BAA6B,EAAE,WAAW;EAC1C,gBAAgB,EAAE,QAAQ;EAC1B,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,4BAA4B;EAC5B,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,SAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.main-page {\n  display: flex;\n  height: 100vh;\n  width: calc(100vw - 0px); /* スクロールバー幅を考慮 */\n  overflow: hidden;\n}\n.mainpage-article-area h2 {\n  font-size: 1.8rem; /* サイズを少し大きく */\n  font-weight: bold;\n  margin: 20px 0; /* 上下の余白 */\n  padding: 20px 0 10px 15px; /* 左側に 15px のパディングを追加 */\n  border-bottom: 2px solid #ccc; /* 見やすい下線 */\n  text-align: left; /* 左寄せ */\n  color: var(--text-color, #333);\n}\n.mainpage-genre-selector {\n  flex: 0 0 20%;\n  border-right: 1px solid #ccc;\n  height: 100%;\n  overflow-y: auto;\n}\n\n.mainpage-article-area {\n  flex: 0 0 80%;\n  overflow-y: auto;\n}\n\n.mainpage-right-side {\n  flex: 0 0 0%;\n  height: 100%;\n  overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
