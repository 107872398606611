import React, { useState ,useEffect } from 'react';
import './genreSelector.css';
import genresData from './genreAssets'; 
import openIcon from '../../Assets/svg/open.svg';
import closeIcon from '../../Assets/svg/close.svg';

const GenreSelector = ({ onGenreSelect, toggleOpen, isOpen }) => {
  const [selectedGenres, setSelectedGenres] = useState(new Set());
  const [isGenreSelectorOpen, setIsGenreSelectorOpen] = useState(true);

  const updateGenres = (newSelectedGenres) => {
    if (
      newSelectedGenres.size !== selectedGenres.size ||
      [...newSelectedGenres].some((item) => !selectedGenres.has(item))
    ) {
      const genresArray = Array.from(newSelectedGenres);
      localStorage.setItem("selectedGenres", JSON.stringify(genresArray));
      setSelectedGenres(newSelectedGenres);
      onGenreSelect(genresArray);
    }
  };


  const toggleGenreSelector = () => {
    setIsGenreSelectorOpen((prev) => !prev);
  };
  const handleParentGenreClick = (parentGenre) => {
    const newSelectedGenres = new Set(selectedGenres);
    const allSelected = genresData[parentGenre].every((sub) =>
      newSelectedGenres.has(sub)
    );

    if (allSelected) {
      genresData[parentGenre].forEach(sub => newSelectedGenres.delete(sub));
      console.log("genresData:", genresData);
    } else {
      genresData[parentGenre].forEach(sub => newSelectedGenres.add(sub));
      console.log("genresData:", genresData);
    }

    updateGenres(newSelectedGenres);
  };

  const handleSubGenreClick = (subGenre, parentGenre) => {
    const newSelectedGenres = new Set(selectedGenres);
    newSelectedGenres.has(subGenre) ? newSelectedGenres.delete(subGenre) : newSelectedGenres.add(subGenre);

    updateGenres(newSelectedGenres);
  };

  return (
    <div className={`genre-selector ${isGenreSelectorOpen ? 'open' : 'closed'}`}>
      <button className="toggle-button" onClick={toggleGenreSelector}>
        <img src={isGenreSelectorOpen ? closeIcon : openIcon} alt="toggle" />
      </button>
      {isGenreSelectorOpen && (
        <div className="genre-list">
          {Object.keys(genresData).map((parentGenre) => {
            const isParentActive = genresData[parentGenre].every((sub) =>
              selectedGenres.has(sub)
            );
  
            return (
              <div key={parentGenre} className="genre-group">
                <button
                  className={`genre-button ${isParentActive ? 'active' : ''}`}
                  onClick={() => handleParentGenreClick(parentGenre)}
                >
                  {parentGenre}
                </button>
                <div className="sub-genres">
                  {genresData[parentGenre].map((subGenre) => (
                    <button
                      key={subGenre}
                      className={`sub-genre-button ${
                        selectedGenres.has(subGenre) ? 'active' : ''
                      }`}
                      onClick={() => handleSubGenreClick(subGenre)}
                    >
                      {subGenre}
                    </button>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default GenreSelector;
