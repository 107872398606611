import React, { useState, useEffect } from 'react';
import './GenreArticleSliderComponent.css';
import ArticleSliderComponent from '../ArticleSliderComponent/ArticleSliderComponent';
const GenreArticleSliderComponent  = ({ genres }) => {
    const [articlesByGenre, setArticlesByGenre] = useState({});
    const [currentSlide, setCurrentSlide] = useState({});
    const [loading] = useState(false);

  
    useEffect(() => {
        if (!genres || genres.length === 0) return;

        const initialSlideState = {};
        genres.forEach((genre) => {
            initialSlideState[genre] = 0; 
        });
        setCurrentSlide(initialSlideState);

        genres.forEach((genre) => {
            const apiUrl =
                process.env.REACT_APP_ENV === 'Development'
                    ? `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_GENRES}${process.env.REACT_APP_BACK_API_ARTICLE_GENRE}/${encodeURIComponent(
                          genre
                      )}`
                    : `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_GENRES}${process.env.REACT_APP_BACK_API_ARTICLE_GENRE}/${encodeURIComponent(
                          genre
                      )}`;

            fetch(apiUrl)
                .then((response) => response.json())
                .then((data) => {
                    setArticlesByGenre((prev) => ({
                        ...prev,
                        [genre]: data,
                    }));
                });
        });
    }, [genres]);


    return (
        <div className="genre-article-slider-container">
            {Object.entries(articlesByGenre).map(([genre, articles]) => (
                <div key={genre} className="genre-article-slider-wrapper">
                    <div className="genre-article-slider-header">
                        <h2 className="genre--articleslider-title">{genre}</h2>
                        <a href={`/genres/${genre}`} className="genre-article-slider-read-more-link">
                            Read more...
                        </a>
                    </div>
                   
                    <ArticleSliderComponent articles={articles} />
                </div>
            ))}
        </div>
    );
};

export default GenreArticleSliderComponent;