import React, { useState, useEffect } from "react";
import "./FavoriteMarker_v2.css";

const FavoriteMarker_v2 = ({ article }) => {
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    const favorites = JSON.parse(localStorage.getItem("favorites") || "[]");
    setIsFavorite(favorites.some((item) => item.UID === article.UID));
  }, [article.UID]);

  const toggleFavorite = () => {
    let favorites = JSON.parse(localStorage.getItem("favorites") || "[]");
    if (isFavorite) {
      favorites = favorites.filter((item) => item.UID !== article.UID);
      setIsFavorite(false);
    } else {
      if (favorites.length >= 50) favorites.shift();
      favorites.push({
        UID: article.UID,
        ReportName: article.ReportName,
        ProviderName: article.ProviderName,
        Genres: article.Genres,
        CreatedDate: article.CreatedDate,
      });
      setIsFavorite(true);
    }
    localStorage.setItem("favorites", JSON.stringify(favorites));
  };

  // 塗りつぶし色を設定
  const fillColor = isFavorite ? "var(--favorite-fill-active)" : "none"; // 塗りつぶし色
  const strokeColor = isFavorite ? "var(--favorite-stroke-active)" : "var(--favorite-stroke)"; // 枠線色

  return (
    <button
      className={`favorite-button ${isFavorite ? "active" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
        toggleFavorite();
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={fillColor} /* 塗りつぶし色 */
        stroke={strokeColor} /* 枠線色 */
        strokeWidth="50"
      >
        <path d="M200-120v-640q0-33 23.5-56.5T280-840h400q33 0 56.5 23.5T760-760v640L480-240 200-120Zm80-122 200-86 200 86v-518H280v518Zm0-518h400-400Z" />
      </svg>
    </button>
  );
};

export default FavoriteMarker_v2;