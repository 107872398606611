// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* デフォルト状態 */
.favorite-button svg {
    fill: var(--favorite-fill-default); 
    stroke: var(--favorite-stroke); /* デフォルト枠線色 */
    stroke-width: 2px; /* 枠線の太さ */
    transition: all 0.3s ease; /* スムーズな変化 */
  }
  
  /* ホバー状態 */
  .favorite-button:hover svg {
    fill: var(--favorite-hover-fill); /* ホバー時の塗りつぶし色 */
    stroke: var(--favorite-hover-stroke); /* ホバー時の枠線色 */
    transform: scale(1.1); /* 少し拡大して浮き上がる効果 */
  }
  
  /* アクティブ状態（お気に入り追加） */
  .favorite-button.active svg {
    fill: var(--favorite-active-fill); /* アクティブ時の塗りつぶし色 */
    stroke: var(--favorite-active-stroke); /* アクティブ時の枠線色 */
  }
  `, "",{"version":3,"sources":["webpack://./src/Component/Items/FavoriteMarker_v2/FavoriteMarker_v2.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;IACI,kCAAkC;IAClC,8BAA8B,EAAE,aAAa;IAC7C,iBAAiB,EAAE,UAAU;IAC7B,yBAAyB,EAAE,YAAY;EACzC;;EAEA,UAAU;EACV;IACE,gCAAgC,EAAE,gBAAgB;IAClD,oCAAoC,EAAE,aAAa;IACnD,qBAAqB,EAAE,kBAAkB;EAC3C;;EAEA,qBAAqB;EACrB;IACE,iCAAiC,EAAE,kBAAkB;IACrD,qCAAqC,EAAE,eAAe;EACxD","sourcesContent":["/* デフォルト状態 */\n.favorite-button svg {\n    fill: var(--favorite-fill-default); \n    stroke: var(--favorite-stroke); /* デフォルト枠線色 */\n    stroke-width: 2px; /* 枠線の太さ */\n    transition: all 0.3s ease; /* スムーズな変化 */\n  }\n  \n  /* ホバー状態 */\n  .favorite-button:hover svg {\n    fill: var(--favorite-hover-fill); /* ホバー時の塗りつぶし色 */\n    stroke: var(--favorite-hover-stroke); /* ホバー時の枠線色 */\n    transform: scale(1.1); /* 少し拡大して浮き上がる効果 */\n  }\n  \n  /* アクティブ状態（お気に入り追加） */\n  .favorite-button.active svg {\n    fill: var(--favorite-active-fill); /* アクティブ時の塗りつぶし色 */\n    stroke: var(--favorite-active-stroke); /* アクティブ時の枠線色 */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
