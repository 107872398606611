
import React , { useEffect }from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider} from './Contexts/ThemeContext';
import AppContent from './App_Content';  // AppContent コンポーネントをインポート
import './style/theme.css';
import './style/global.css';
const App = () => {
  useEffect(() => {
    //console.log('App component mounted');
    return () => {
      //console.log('App component will unmount');
    };
  }, []);
  //console.log('App.js re-rendered');
  return (
    <Router> {/* Router でルーティング管理 */}
      <ThemeProvider> {/* ThemeProvider で全てをラップ */}
      
      
        <AppContent /> 
      
     
      </ThemeProvider>
    </Router>
  );
};

export default App;
