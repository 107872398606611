import React, { useEffect, useState,useMemo,useRef } from "react";
import "./EditorsPickComponent.css";
import { useNavigate } from "react-router-dom";
import ArticleSliderComponent from '../ArticleSliderComponent/ArticleSliderComponent';
const EditorsPickComponent = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0); // 現在のインデックス
  const itemsPerPage = 5;
  const apiUrl = useMemo(() => {
    if (process.env.REACT_APP_ENV === "Development") {
      return `${process.env.REACT_APP_BACK_API_DEV_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_API_ARTICLE_EDITORPICK}`;
    } else {
      return `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_BACK_API_ARTICLE}${process.env.REACT_APP_API_ARTICLE_EDITORPICK}`;
    }
  }, []);

  useEffect(() => {
    const fetchEditorsPick = async () => {
      setLoading(true); 
      setError(null); 
  
      try {
        const response = await fetch(apiUrl, {
          method: "GET", 
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json(); 
        setArticles(data || []); 
      } catch (err) {
        setError("記事情報が取得できませんでした"); 
      } finally {
        setLoading(false); 
      }
    };
    fetchEditorsPick(); 
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return <ArticleSliderComponent articles={articles} />;
};

export default EditorsPickComponent;