import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';  
import MainPage from './Component/MainPage/MainPage';
import ArticleDetailPage from './Component/ArticleDetailPage/ArticleDetailPage';
import FavoriteList from './Component/FavoriteList/FavoriteList'
import HistoryList from './Component/HistoryList/HistortList'
import SearchResults from './Component/SearchResults/SearchResults'
import GenrePage from './Component/GenrePage/GenrePage'
import SharePage from './Component/SharePage/SharePage';
import ProviderArticlesPage from './Component/ProviderArticlesPage/ProviderArticlesPage'
import AboutUs from './Component/AboutUs/AboutUs'

function App_Routes() {
  useEffect(() => {
    //console.log('App_Routes component mounted');
    return () => {
      //console.log('App_Routes component will unmount');
    };
  }, []);

  return (
  
       <Routes>
         <Route path="/" element={<MainPage />} />
         <Route path="/article/:articleId" element={<ArticleDetailPage />} />
         <Route path="/favorites" element={<FavoriteList />} /> 
         <Route path="/history" element={<HistoryList />} /> 
         <Route path="/search" element={<SearchResults />} />
         <Route path="/genres/:genre" element={<GenrePage />} />
         <Route path="/sharepage" element={<SharePage />} />
         <Route path="/aboutus" element={<AboutUs />} />
         <Route path="/provider/:providerName" element={<ProviderArticlesPage />} />
         <Route path="*" element={<h1>404 - ページが見つかりません</h1>} />
       </Routes> 
  );
}

export default App_Routes;
