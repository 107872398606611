
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import FavoriteMarker_v2 from "../Items/FavoriteMarker_v2/FavoriteMarker_v2";
import ArrowForwardIcon from "../../Assets/svg/arrow_forward.svg";
import ArrowBackIcon from "../../Assets/svg/arrow_back.svg";
import "./ArticleSliderComponent.css";

const ArticleSliderComponent = ({ articles }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 5; // 一度に表示する記事数（必要に応じて変更してください）
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const nextArticle = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 < articles.length ? prevIndex + 1 : 0
    );
  };

  const prevArticle = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 >= 0 ? prevIndex - 1 : articles.length - 1
    );
  };

  const getVisibleArticles = () => {
    // 現在のインデックスから itemsPerPage 分の記事を取得
    // 配列の終端に達した場合は先頭から補完
    return articles
      .slice(currentIndex, currentIndex + itemsPerPage)
      .concat(
        articles.slice(0, Math.max(0, currentIndex + itemsPerPage - articles.length))
      );
  };

  const handleArticleClick = (articleUID) => {
    navigate(`/article/${articleUID}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="article-slider-component">
      <div className="article-slider-component-container" ref={sliderRef}>
        {getVisibleArticles().map((article) => (
          <div
            key={article.UID}
            className="article-slider-component-item"
            onClick={() => handleArticleClick(article.UID)}
          >
            <div className="article-slider-component-item-header">
              <span>{article.ProviderName || "提供元不明"}</span>
            </div>
            <div className="article-slider-component-item-title">
              <h3>{article.ReportName || "タイトル不明"}</h3>
            </div>
            <div className="article-slider-component-item-meta">
              <span>
                {article.CreatedDate
                  ? new Date(article.CreatedDate).toLocaleDateString()
                  : "更新日不明"}
              </span>
            </div>
            <div className="article-slider-component-item-genres">
              <span>{article.Genres || "ジャンル情報なし"}</span>
            </div>
            <div className="article-slider-component-item-favorite">
              <FavoriteMarker_v2 article={article} />
            </div>
          </div>
        ))}
      </div>

      <button className="article-slider-component-button prev" onClick={prevArticle}>
        <img src={ArrowBackIcon} alt="Prev" />
      </button>

      <button className="article-slider-component-button next" onClick={nextArticle}>
        <img src={ArrowForwardIcon} alt="Next" />
      </button>

      {/* ページインジケーター */}
      <div className="article-slider-component-indicators">
        {articles.map((_, index) => (
          <span
            key={index}
            className={`article-slider-component-indicator ${index === currentIndex ? "active" : ""}`}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ArticleSliderComponent;